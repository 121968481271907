import React from 'react';
import styled from 'styled-components';

import { Box, Button, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';

const StickerWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 8px;
  width: 115px;
  text-align: center;
`;

const PricingCardTablet = ({
  isHighlighted,
  image,
  stickerLabel,
  title,
  currency,
  pricePerMonth,
  pricePeriod,
  billingDescription,
  button,
}) => (
  <Grid.Container
    py={16}
    pl={24}
    pr={40}
    backgroundColor="white"
    boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
    borderRadius={4}
  >
    <Grid.Row alignItems="center">
      <Grid.Col size={6} pr={12}>
        <Grid.Row
          position="relative"
          alignItems="center"
          justifyContent="center"
          height={128}
          pb={16}
          pt={stickerLabel ? 0 : 16}
        >
          <ImageCore src={image.src} alt={image.alt} height="100%" />
          {stickerLabel && (
            <StickerWrapper backgroundColor={isHighlighted ? 'success' : 'darkBlue_40'}>
              <Text.Body4Strong color="white" lineHeight="24px">
                {stickerLabel}
              </Text.Body4Strong>
            </StickerWrapper>
          )}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col size={6} pl={12}>
        <Grid.Row justifyContent="space-between" alignItems="center" mb={8}>
          <Text.Body3Strong mr={8}>{title}</Text.Body3Strong>
          <Box display="flex" alignItems="flex-end">
            <Text.Subheading2 color="primary" mr={3}>
              {pricePerMonth}
            </Text.Subheading2>
            <Text.Body3Strong color="primary" lineHeight="28px">
              {`${currency}${pricePeriod}`}
            </Text.Body3Strong>
          </Box>
        </Grid.Row>
        <Text.Body4 color="darkBlue_60" mb={24}>
          {billingDescription}
        </Text.Body4>
        {button && (
          <Box width="100%">
            <LinkWrapper link={button.link} target="_blank" withParams>
              <Button
                variant={isHighlighted ? 'default' : 'outlined'}
                customEvent={button?.buttonProps?.customEvent}
                fullWidth
              >
                {button.title}
              </Button>
            </LinkWrapper>
          </Box>
        )}
      </Grid.Col>
    </Grid.Row>
  </Grid.Container>
);

export default PricingCardTablet;
