import React from 'react';

import { Box, Grid, Show } from '@core';
import { Loader } from '@components';

import { PricingCardDesktop, PricingCardMobile, PricingCardTablet } from './layouts';

const PricingCard = ({ data, isLoading }) => (
  <Box width="100%" minWidth={{ _: 280, sm: 530, lg: 268 }}>
    {isLoading ? (
      <Grid.Container
        justifyContent="center"
        height={{ _: 200, sm: 160, lg: 430 }}
        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
        borderRadius={4}
      >
        <Loader isLoading />
      </Grid.Container>
    ) : (
      <React.Fragment>
        <Show.Mobile>
          <PricingCardMobile {...data} />
        </Show.Mobile>

        <Show.TabletOnly>
          <PricingCardTablet {...data} />
        </Show.TabletOnly>

        <Show.DesktopAndUp>
          <PricingCardDesktop {...data} />
        </Show.DesktopAndUp>
      </React.Fragment>
    )}
  </Box>
);

export default PricingCard;
