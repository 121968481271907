import React from 'react';
import styled from 'styled-components';

import { Box, Button, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';

const StickerWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 8px;
  width: 148px;
  text-align: center;
`;

const PricingCardDesktop = ({
  isHighlighted,
  image,
  stickerLabel,
  title,
  currency,
  pricePerMonth,
  pricePeriod,
  billingDescription,
  button,
}) => (
  <Grid.Container
    px={40}
    pt={32}
    pb={40}
    backgroundColor="white"
    boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
    borderRadius={4}
  >
    <Grid.Row height={isHighlighted ? 200 : 164} alignItems="center" mb={16}>
      <Grid.Col position="relative" display="flex" justifyContent="center" pb={stickerLabel && 26}>
        <ImageCore src={image.src} alt={image.alt} />
        {stickerLabel && (
          <StickerWrapper backgroundColor={isHighlighted ? 'success' : 'darkBlue_40'}>
            <Text.Body2 color="white">{stickerLabel}</Text.Body2>
          </StickerWrapper>
        )}
      </Grid.Col>
    </Grid.Row>
    <Text.Body3Strong mb={24}>{title}</Text.Body3Strong>
    <Grid.Row justifyContent="center" alignItems="center" mb={8} maxWidth={{ lg: 171, xxl: 252 }}>
      <Text.Body1Strong color="primary">{currency}</Text.Body1Strong>
      <Text.Heading1 color="primary" mx={8}>
        {pricePerMonth}
      </Text.Heading1>
      <Text.Body1Strong color="primary">{pricePeriod}</Text.Body1Strong>
    </Grid.Row>
    <Text.Body4 textAlign="center" color="darkBlue_60" lineHeight="15px" mb={24}>
      {billingDescription}
    </Text.Body4>
    {button && (
      <Box width="100%">
        <LinkWrapper link={button.link} target="_blank" withParams>
          <Button
            variant={isHighlighted ? 'default' : 'outlined'}
            customEvent={button?.buttonProps?.customEvent}
            fullWidth
          >
            {button.title}
          </Button>
        </LinkWrapper>
      </Box>
    )}
  </Grid.Container>
);

export default PricingCardDesktop;
