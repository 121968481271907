import React from 'react';

import { Box, Button, Grid } from '@core';
import { LinkWrapper, Text } from '@components';

const PricingCardMobile = ({
  isHighlighted,
  stickerLabel,
  title,
  currency,
  pricePerMonth,
  pricePeriod,
  billingDescription,
  button,
}) => (
  <Grid.Container
    alignItems="flex-start"
    py={24}
    px={32}
    backgroundColor="white"
    boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
    borderRadius={4}
    position="relative"
  >
    {stickerLabel && (
      <Box
        alignSelf="center"
        backgroundColor={isHighlighted ? 'success' : 'darkBlue_40'}
        borderRadius="md"
        top={-12}
        position="absolute"
        width={115}
      >
        <Text.Body4Strong color="white" lineHeight="24px" textAlign="center">
          {stickerLabel}
        </Text.Body4Strong>
      </Box>
    )}
    <Text.Body3Strong mb={8}>{title}</Text.Body3Strong>
    <Grid.Row alignItems="flex-end" mb={8}>
      <Text.Subheading2 color="primary" mr={3}>
        {pricePerMonth}
      </Text.Subheading2>
      <Text.Body3Strong color="primary" lineHeight="28px">
        {`${currency}${pricePeriod}`}
      </Text.Body3Strong>
    </Grid.Row>
    <Text.Body4 color="darkBlue_60" mb={16}>
      {billingDescription}
    </Text.Body4>
    {button && (
      <Box width="100%">
        <LinkWrapper link={button.link} target="_blank" withParams>
          <Button
            variant={isHighlighted ? 'default' : 'outlined'}
            customEvent={button?.buttonProps?.customEvent}
            fullWidth
          >
            {button.title}
          </Button>
        </LinkWrapper>
      </Box>
    )}
  </Grid.Container>
);

export default PricingCardMobile;
